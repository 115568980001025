const makeWPCall = async (input, wp_ajax_obj) => {
  const data = new FormData();

  data.append('action', 'autocompleteAjaxCallback');
  data.append('nonce', wp_ajax_obj.ajax_nonce);
  data.append('search_term', input);

  try {
    const response = await fetch(wp_ajax_obj.ajax_url, {
      method: "POST",
      credentials: "same-origin",
      body: data
    });

    return await response.json();
  } catch (error) {
    console.log('error ', error)
  }
}

const getResults = async (input, wp_ajax_obj) => {
  // make a fetch call to WP
  try {
    const response = await makeWPCall(input, wp_ajax_obj);

    return await response;
  } catch (error) {
    return error;
  }
}

const displayResults = (data, resultsContainer) => {
  resultsContainer.style.display = "block";
  resultsContainer.innerHTML = "";

  data.forEach((result) => {
    resultsContainer.innerHTML += `<li class="search-item">${result.label}</li>`;
  });
}

const resultsContainerClick = (resultsContainer, searchInput) => {
  resultsContainer.onclick = (e) => {
    const input = searchInput.querySelector("input[id='search']");
    const setValue = e.target.innerText;
    input.value = setValue;
    resultsContainer.innerHTML = "";

    const searchButton = document.querySelector("input[class=\"search-icon\"]");

    searchButton.click();
  }
}

const searchInputOnInput = (resultsContainer, searchInput, wp_ajax_obj) => {
  searchInput.oninput = (e) => {
    const userInput = e.target.value;

    resultsContainer.innerHTML = "";

    if (userInput.length < 3) {
      return;
    }

    getResults(userInput, wp_ajax_obj).then((data) => {
      displayResults(data, resultsContainer);
    });
  }
}

window.addEventListener("load", () => {
  const searchInput = document.getElementById("search-form");
  const wp_ajax_obj = autocomplete_search_ajax;

  if (!searchInput || !wp_ajax_obj || Object.keys(wp_ajax_obj).length === 0) {
    return;
  }

  const resultsContainer = document.getElementById("search-results");

  searchInputOnInput(resultsContainer, searchInput, wp_ajax_obj);

  resultsContainerClick(resultsContainer, searchInput);
});